import { Injectable } from "@angular/core";
import { gql } from "apollo-angular";
import { mergeMap, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SavingsGraphqlClientService } from "src/app/modules/graphql-clients/services/savings-graph-client.service";
import { getApolloResponseData } from "src/app/modules/graphql-clients/utils/apollo-base-wrapper";
import { PortfolioInsight, Query } from "src/app/services/api/savings-and-pension-queries.types";

type GetPortfolioInsightQuery = Pick<Query, "portfolioInsight">;

const GET_PORTFOLIO_INSIGHT_QUERY = gql`
  query getPortfolioInsightQuery($ids: [String], $fromDate: ISO8601Date, $toDate: ISO8601Date) {
    headers @client {
      correlationId
    }
    portfolioInsight(ids: $ids, fromDate: $fromDate, toDate: $toDate) {
      keyValues {
        marketValue {
          value
        }
        totalReturn {
          value
        }
        totalReturnPct
        annualAverageReturnPct
      }
    }
  }
`;

@Injectable({
  providedIn: "root",
})
export class PortfolioInsightQueriesService {
  constructor(private readonly savingsGraphqlClient: SavingsGraphqlClientService) {}

  public getPortfolioInsight(ids: string[], fromDate?: string, toDate?: string): Observable<PortfolioInsight> {
    const variables = { ids, fromDate, toDate };

    return this.savingsGraphqlClient
      .query<GetPortfolioInsightQuery>({
        query: GET_PORTFOLIO_INSIGHT_QUERY,
        variables,
      })
      .pipe(
        mergeMap(getApolloResponseData),
        map(({ portfolioInsight }) => portfolioInsight),
      );
  }
}
